import { Page, PageContent } from '@/components/layout/page';
import { ErrorBanner } from './error-banner';
import { errorContainer } from './page.css';

export const ErrorPage = ({
  withContactUs = false,
}: {
  withContactUs?: boolean;
}) => {
  return (
    <Page>
      <PageContent className={errorContainer}>
        <ErrorBanner withContactUs={withContactUs} />
      </PageContent>
    </Page>
  );
};
